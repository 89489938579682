<template>
  <div class="app-container">
    <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" />
    <el-button @click="upload">确认提交</el-button>
    <el-button @click="exportExcel" type="primary">下载模板</el-button>
    <el-table :data="tableData" border highlight-current-row style="width: 100%;margin-top:20px;">
      <el-table-column v-for="item of tableHeader" :key="item" :prop="item" :label="item" />
    </el-table>
  </div>
</template>

<script>
import UploadExcelComponent from '@/components/UploadExcel/index.vue'
import XLSX from "xlsx";

export default {
  name: 'UploadExcel',
  components: { UploadExcelComponent },
  data() {
    return {
      tableData: [],
      tableHeader: []
    }
  },
  methods: {
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1

      if (isLt1M) {
        return true
      }

      this.$message({
        message: 'Please do not upload files larger than 1m in size.',
        type: 'warning'
      })
      return false
    },
    handleSuccess({ results, header }) {
      this.tableData = results
      this.tableHeader = header
      console.log( this.tableData)

    },
    async upload(){
      const {data:res} = await this.$http.post('goods/import', {'item':this.tableData})
      if(res.code!=200)return this.$message.error(res.msg)
      this.$message.success(res.msg)
    },
    //下载模板
    async exportExcel(){
      const  {data:res} = await this.$http.get('goods/exportTemplate')
      if (res.code!=200)return this.$message.error(res.msg)
      let ws = XLSX.utils.aoa_to_sheet(res.data.data)
      let wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, res.data.name) // 工作簿名称
      XLSX.writeFile(wb, res.data.name) // 保存的文件名
      // this.$message.success(res.msg)
    },
  }
}
</script>
